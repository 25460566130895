import React from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import CheckIcon from '@mui/icons-material/CheckCircle';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDButton from "components/MDButton";

import yourRep from "assets/partridge/Brysen.jpg";

const SuccessPage = ({ todoId, onReset, todoItems }) => {
    // Check if we have Finicity or Plaid in the todo items
    // Find the relevant todo items
    const finicityTodo = todoItems?.find(item => item.definitionId === "Finicity");
    const plaidTodo = todoItems?.find(item => item.definitionId === "Plaid");
    const statementsTodo = todoItems?.find(item => item.definitionId === "StatementsUpload");

    const hasAccountLink = finicityTodo || plaidTodo;
    const hasStatementsUpload = statementsTodo;

    const handleReset = async (todoItemId) => {
        try {
            await ApplicantApiClient.put(`/api/applicant/todoItem/${todoItemId}/reset`);
            if (onReset) {
                onReset();
            }
        } catch (error) {
            console.error('Error resetting todo item:', error);
        }
    };

    const handleClose = () => {
        window.close();
    };

    return (
        <MDBox>
            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                <MDTypography variant="body2" color="text" mb={3}>
                    Thank you for taking the time to fill that out. I'll be reaching out soon to help move this process forward.
                </MDTypography>
                <MDBox mb={1}>
                    <MDTypography variant="h5" fontWeight="regular">
                        Looking forward to doing business with you!
                    </MDTypography>
                </MDBox>
            </MDBox>
            

            <MDBox mt={4} textAlign="center">
                {hasAccountLink ? (
                    <MDBox mb={2}>
                        <MDTypography variant="button" mb={2} display="block">
                            Would you like to try connecting your bank accounts instead?
                        </MDTypography>
                        <MDButton
                            variant="outlined"
                            color="info"
                            onClick={() => handleReset(finicityTodo?.todoId)}
                            sx={{ mr: 2 }}
                        >
                            Link Bank Account
                        </MDButton>
                    </MDBox>
                ) : hasStatementsUpload && (
                    <MDBox mb={2}>
                        <MDTypography variant="button" mb={2} display="block">
                            Would you like to upload additional bank statements?
                        </MDTypography>
                        <MDButton
                            variant="outlined"
                            color="info"
                            onClick={() => handleReset(statementsTodo?.todoId)}
                            sx={{ mr: 2 }}
                        >
                            Upload More Statements
                        </MDButton>
                    </MDBox>
                )}

                <MDButton
                    variant="gradient"
                    color="dark"
                    onClick={handleClose}
                >
                    Close Window
                </MDButton>
            </MDBox>
        </MDBox>
    );
};

export default SuccessPage;