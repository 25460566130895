import React, { useState, useEffect } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { Connect, ConnectEventHandlers, ConnectOptions, ConnectDoneEvent,
    ConnectCancelEvent, ConnectErrorEvent, ConnectRouteEvent } from 'connect-web-sdk';

import CircularProgress from '@mui/material/CircularProgress';

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CheckIcon from '@mui/icons-material/CheckCircle';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import {Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";

import yourRep from "assets/partridge/Brysen.jpg";

const FinicityPage = ({ todoId, onBack, onCompleted}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isConnectDone, setIsConnectDone] = useState(false);
    const [state, setState] = useState(null);

    useEffect(() => {

        ApplicantApiClient.get('api/applicant/finicity/' + todoId)
        .then((data) => {
            setState(data);
            setIsLoading(false);

        })
        .catch((error) => {

            setIsLoading(false);
        });
    }, []);


    const postLogEntry = (event) => {
        ApplicantApiClient.post(`/api/applicant/todoitem/${todoId}/events`, event)
        .then(() => {
            console.log('event written');
        })
        .catch((error) => { 
            console.error(error);
        });
    };

    const connectEventHandlers = {
        onDone: (event) => { handleDone(event); },
        onCancel: (event) => { postLogEntry(event); },
        onError: (event) => { postLogEntry(event); },
        onRoute: (event) => { postLogEntry(event); },
        onUser: (event) => { postLogEntry(event); },
        onLoad: () => { postLogEntry('loaded'); }
      };

    const connectOptions = {
        overlay: 'rgba(199,201,199, 0.5)'
      };

    const launchConnect = () => {
        Connect.launch( state.connectUrl, connectEventHandlers, connectOptions );
    }

    const handleDone = (event) => {
        setIsLoading(true);
        console.log("all done!");
        postLogEntry(event);
        ApplicantApiClient.put('api/applicant/finicity/' + todoId, state)
        .then((data) => {
            setIsLoading(false);
            setIsConnectDone(true);
        })
        .catch((error) => {
            setIsLoading(false);
            console.error(error);
        });
    }
    
    const onSubmit = () => {
        if (onCompleted) {
            onCompleted();
        }
    };

    const isRequired = (message) => (value) => (!!value ? undefined : message);

    return (
            <Grid container>
                <Grid item xs={12} lg={12} sx={{textAlign:"center", alignItems:"center", margin: "auto"}} >
                {isConnectDone &&
                <>
                    <Grid container>
                        <Grid item xs={3} lg={3}>
                            <MDBox
                                justifyContent="center"
                                alignItems="center"
                                width="9rem"
                                height="9rem"
                                variant="gradient"
                                bgColor="success"
                                color="white"
                                shadow="md"
                                borderRadius="xl" 
                                mt={4}
                                ml={20}
                            >
                                <CheckIcon sx={{width: "8rem", height: "8rem"}} />
                            </MDBox>
                        </Grid>
                        <Grid item xs={9} lg={9} mt={5}>
                            <MDTypography variant="h6">Account Link Setup!</MDTypography>
                            <MDTypography variant="caption">Please hit "Next" to continue</MDTypography>
                        </Grid>
                    </Grid>
                </>
                }
                {!isConnectDone &&
                    <>
                    <MDBox lineHeight={0}>
                        <MDTypography variant="h5">Link Account</MDTypography>
                        <MDTypography variant="button" color="text">
                            The following button will launch a secure process for you to grant limited access various accounts for loan processing confirmation.
                        </MDTypography>
                    </MDBox>
                    <Grid item xs={12} lg={12}>
                        <MDBox mt={1.625}>
                            <Grid container spacing={3}>
                                <Grid item md={12} textAlign="center" alignContent="center">
                                    {isLoading &&
                                        <CircularProgress />
                                    }
                                    {!isLoading &&
                                        <MDButton onClick={launchConnect} variant="gradient" color="primary">Launch Finicity!</MDButton>
                                    }
                                </Grid>
                            </Grid>
                        </MDBox>
                    </Grid>
                    </>
                }
                </Grid>
                <Grid item xs={12} lg={12}>
                    <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                        <MDButton
                            type="button"
                            variant="gradient"
                            color="dark"
                            onClick={onSubmit}
                        >
                            Next
                        </MDButton>
                    </MDBox>
                </Grid>
            </Grid>
    )
}

export default FinicityPage;