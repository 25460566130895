import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from "react-oidc-context";

const ApplicationContext = createContext(null);

export const ApplicationProvider = ({ children }) => {
    const auth = useAuth();
    const [authContext, setAuthContext] = useState({
        applicationId: null,
        contactId: null,
        tenantId: null,
        isReady: false
    });

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            // Extract claims from the ID token
            const claims = auth.user.profile;

            setAuthContext({
                applicationId: claims.application_id,
                contactId: claims.contact_id,
                tenantId: claims.tenant_id,
                isReady: true
            });
        }
    }, [auth.isAuthenticated, auth.user]);

    return (
        <ApplicationContext.Provider value={authContext}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => {
    const context = useContext(ApplicationContext);
    if (!context) {
        throw new Error('useApplication must be used within an ApplicationProvider');
    }
    return context;
};