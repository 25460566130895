import React, { useState, useEffect, useCallback } from 'react';
import { ApplicantApiClient } from 'providers/ApplicantApiClient';
import { TenantApiClient } from 'providers/TenantApiClient';

import Grid from "@mui/material/Grid";
import CircularProgress from '@mui/material/CircularProgress';

import MDBox from "components/MDBox";
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import MDButton from 'components/MDButton';
import TextField from 'components/Form/TextField';
import { Formik, Form } from "formik";
import * as Yup from 'yup';

import yourRep from "assets/partridge/Brysen.jpg";

const contactInfoSchema = Yup.object().shape({
    firstName: Yup.string()
        .min(1, 'First Name must be longer')
        .max(150, 'First Name is too long.')
        .required('First Name is required'),
    lastName: Yup.string()
        .min(1, 'Last Name must be longer')
        .max(150, 'Last Name is too long')
        .required('Last Name is required'),
    email: Yup.string()
        .email('Invalid email')
        .required('Email is required'),
    mobilePhone: Yup.string()
        .min(10, 'Phone number must be longer')
        .max(14, 'Phone number too long')
        .required('Mobile Phone is required')
});

const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, "");
    if (cleaned.length <= 3) {
        return `(${cleaned}`;
    } else if (cleaned.length <= 6) {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
    } else {
        return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }
};

const ContactInfoPage = ({ todoId, onBack, onCompleted }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [member, setMember] = useState(null);
    const [contactInfo, setContactInfo] = useState({
        firstName: "",
        lastName: "",
        email: "",
        mobilePhone: ""
    });

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);
            const data = await ApplicantApiClient.get('api/applicant/contactInfo/' + todoId);

            // Format mobile phone on load
            if (data.mobilePhone) {
                data.mobilePhone = formatPhoneNumber(data.mobilePhone);
            }

            // Update states
            setContactInfo(data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    }, [todoId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const onSubmit = async (values) => {
        try {
            // Remove formatting before submitting
            const formattedValues = {
                ...values,
                mobilePhone: values.mobilePhone.replace(/\D/g, "")
            };

            await ApplicantApiClient.put('api/applicant/contactInfo/' + todoId, formattedValues);

            if (onCompleted) {
                onCompleted();
            }
        } catch (error) {
            console.error('Submit error:', error);
        }
    };

    if (isLoading) {
        return (
            <div>
                <CircularProgress color="dark" />
            </div>
        );
    }
        return (
            <Formik
                initialValues={contactInfo}
                validationSchema={contactInfoSchema}
                onSubmit={onSubmit}
                enableReinitialize  // Important for updating form with fetched data
            >
                {({ values, setFieldValue, errors, touched, isSubmitting }) => (
                    <Form>
                        <MDBox>
                            <MDBox width="82%" textAlign="center" mx="auto" my={4}>
                                <MDTypography variant="body2" color="text" mb={3}>
                                    Greetings! We are excited to help you with your application.
                                </MDTypography>
                                <MDBox mb={1}>
                                    <MDTypography variant="h5" fontWeight="regular">
                                        Let&apos;s start with the basic contact information.
                                    </MDTypography>
                                </MDBox>
                            </MDBox>
                            <MDBox mt={2}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={4} container justifyContent="center">
                                        <MDBox position="relative" height="max-content" mx="auto">
                                            <MDAvatar src={yourRep} alt="your lending agent" size="xxl" shadow="md" />
                                            <br />
                                            <MDTypography variant="h5" fontWeight="regular">
                                                {member && member.name ? member.name : <>&nbsp;</>}
                                            </MDTypography>
                                        </MDBox>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <MDBox mb={2}>
                                            <TextField
                                                name="firstName"
                                                type="text"
                                                label="First Name"
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <TextField
                                                name="lastName"
                                                type="text"
                                                label="Last Name"
                                            />
                                        </MDBox>
                                        <MDBox mb={2}>
                                            <TextField name="email" type="email" label="Email Address" />
                                        </MDBox>
                                        <MDBox>
                                            <TextField
                                                name="mobilePhone"
                                                type="text"
                                                label="Mobile Phone"
                                                value={values.mobilePhone}
                                                onChange={(e) => {
                                                    const formattedPhone = formatPhoneNumber(e.target.value);
                                                    setFieldValue("mobilePhone", formattedPhone);
                                                }}
                                            />
                                        </MDBox>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox mt={2} width="100%" display="flex" justifyContent="right">
                                <MDButton
                                    disabled={isSubmitting}
                                    type="submit"
                                    variant="gradient"
                                    color="dark"
                                >
                                    Get Started
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </Form>
                )}
            </Formik>
        );
    }
;

export default ContactInfoPage;
