import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.REACT_APP_APPLICANT_API,
    timeout: 60000
});

instance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    const applicationId = localStorage.getItem('applicationId');
    const contactId = localStorage.getItem('contactId');

    config.headers['Content-Type'] = 'application/json';
    config.headers['Authorization'] = `Bearer ${token}`;

    if (applicationId) {
        config.headers['X-Application-ID'] = applicationId;
    }
    if (contactId) {
        config.headers['X-Contact-ID'] = contactId;
    }

    return config;
});

const responseBody = (response) => response.data;

const requests = {
    get: (url) => instance.get(url).then(responseBody),
    post: (url, body) => instance.post(url, body).then(responseBody),
    put: (url, body) => instance.put(url, body).then(responseBody),
    delete: (url) => instance.delete(url).then(responseBody),
};

export const ApplicantApiClient = requests;