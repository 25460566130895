import React from 'react';
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import ConditionalWizard from './ConditionalWizard';
import ErrorDialog from 'components/ErrorDialog';
import BusyPopup from 'components/BusyPopup';

function WizardPage() {
    return (
        <MDBox py={3} mb={20} height="65vh">
            <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: { xs: 2, md: 6 } }}>
                <Grid item xs={12} sm={12} lg={8}>
                    <ConditionalWizard />
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default WizardPage;