import React, { useEffect } from "react";
import { useAuth } from "react-oidc-context";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ApplicationProvider } from "./contexts/ApplicationContext";
import theme from "./assets/theme";
import Navbar from "./components/Navbar";
import DemoPage from "./pages/Demo/DemoMode";
import NewWizardPage from "./pages/Demo/NewWizard";
import WizardPage from "./pages/Wizard";
import useBrandingStore from "./store/brandStore";

const router = createBrowserRouter([
    {
        path: "/",
        element: <WizardPage />,
    },
    {
        path: "/demo",
        element: <NewWizardPage />,
    },
]);

function App() {
    const auth = useAuth();
    const { setDisplayName, setHeroImage } = useBrandingStore();
    const queryParams = new URLSearchParams(window.location.search);
    const invitation = queryParams.get("invitation");

    useEffect(() => {
        if (auth.isAuthenticated && auth.user) {
            const claims = auth.user.profile;
            localStorage.setItem('token', auth.user.access_token);
            localStorage.setItem('applicationId', claims.application_id);
            localStorage.setItem('contactId', claims.contact_id);
        }
    }, [auth.isAuthenticated, auth.user]);

    switch (auth.activeNavigator) {
        case "signInSilent":
            return <div>Signing you in...</div>;
        case "signoutRedirect":
            return <div>Signing you out...</div>;
    }

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        return <div>Error: {auth.error.message}</div>;
    }

    if (auth.isAuthenticated) {
        return (
            <ApplicationProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Navbar />
                    <RouterProvider router={router} />
                </ThemeProvider>
            </ApplicationProvider>
        );
    } else {
        auth.signinRedirect({ extraQueryParams: { invitation } });
        return null;
    }
}

export default App;